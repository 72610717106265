<template>
	<edit-template class="add-buildings" @confirm="saveData" @cancel="cancelData" :cancelRemind="false" confirmText="保存" cancelText="取消">

    <el-form ref="form" :model="form" label-width="80rem">
      <el-form-item label="所属校区" required="">
        <el-select :popper-append-to-body="false" v-model="form.school_id" placeholder="请选择">
          <el-option v-for="(item,index) in userInfo.school_arr" :key="index" :label="item" :value="index"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="楼宇名称" required="">
        <el-input v-model="form.dimicile_buliding_name" placeholder="请输入"></el-input>
      </el-form-item>

      <el-form-item label="楼宇用途">
        <el-select v-model="form.usage" multiple="" @change="clickData" placeholder="请选择" ref="c1" :value="form.usage">
          <el-option v-for="item in buildinglist" :value-key="item" :key="item" :label="item" :value="item">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="最低楼层">
        <el-input disabled="" v-model="form.floor_min" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="最高楼层">
        <el-input disabled="" v-model="form.floor_max" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="房间数">
        <el-input v-model="form.num" placeholder="请输入"></el-input>
      </el-form-item>
    </el-form>



</edit-template>
</template>

<script>
import {EditBuildings, buildingsUsage} from "@/api/building";
import {mapGetters} from "vuex";

export default {
	_config:{"route":{"path":"edit","meta":{"title":"编辑"}}},
  data() {
    return {
      form: {
        school_id:37,
        dimicile_buliding_name: "",
        usage: "",
        floor_min: "",
        floor_max: "",
        num: "",
      },
      buildinglist: {},
      tab: true,
    };
  },
  computed:{
    ...mapGetters(["userInfo"])
  },

  methods: {
    async getData() {
      let id = this.$route.query.id;
      this.$_axios.get("/houses/"+id).then(res=>{
        let {data} = res.data;
        data.school_id +="";
        data.usage = data.usage.split(",");
        this.form = data;
      })
      let res = await buildingsUsage();
      this.buildinglist = res.data.data;
    },
    clickData(val) {
      this.form.usage = val;
    },
    async saveData() {
      let usageid = [];
      for (let i in this.buildinglist) {
        this.form.usage.forEach((item) => {
          if (this.buildinglist[i] == item) {
            usageid.push(i);
          }
        });
      }
      let res = await EditBuildings(
          this.$route.query.id,
          {
            school_id:this.form.school_id,
            dimicile_buliding_name: this.form.dimicile_buliding_name,
            usage: usageid.join(","),
            floor_min: this.form.floor_min,
            floor_max: this.form.floor_max,
            num: this.form.num||0,
          });
      let {error} = res.data;
      if (error.errorCode == 1) {
        this.$message({
          type: "error",
          message: error.errorUserMsg,
        });
      } else if (error.errorCode == 0) {
        this.$message({
          type: "success",
          message: "修改成功",
        });
        this.$router.push("./list");
        // this.$router.back()
      }
    },
    cancelData() {
      this.$confirm("是否取消编辑", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$router.back();
      });
    },
  },
  mounted() {

  },
  created() {
    this.getData();
  },
};
</script>

<style lang="scss" scoped>
.add-buildings {
  box-sizing: border-box;

  padding-left: 50rem;
  padding-top: 40rem;
  color: #666;
}

.el-button {
  width: 115rem;
  height: 40rem;
  margin-left: 40rem;
}

.el-form-item {
  margin-top: 20rem;
}

.adressinput {
  width: 568rem;
  margin-left: 120rem;
}

// ::v-deep .el-form-item--small .el-form-item__label{
// width: 120rem !important;
// }
.el-button--primary {
  background-color: #1d2088;
}

</style>
