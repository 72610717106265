// / 楼宇管理接口模块
import request from '@/utils/request'

/* 获取楼宇列表*/
export const getBuildingslist = (page = 1) => {
    return request({
        method: 'get',
        url: '/houses'+'?page=' + page,
         
    })
}

// 新增楼宇
export const AddBuildings = (data) => {
    return request({
        method: 'post',
        url: '/houses',
        data
    })
}

// 修改楼宇
export const EditBuildings = (id,data) => {
    return request({
        method: 'put',
        url: '/houses/'+id,
        data
    })
}

// 删除楼宇
export const delBuildings = (id) => {
    return request({
        method: 'delete',
        url: '/houses/'+ id,
       
    })
}

// 楼宇用途
export const  buildingsUsage = (params) => {
    return request({
        method: 'get',
        url: '/houses/usage',
        params
    })
}
 
         
   
 

 

 
 
 
